import React from 'react'
import SEOTools from '../components/SEOTools'
import DemoSelector from '../components/DemoSelector'

/**
 * @returns {JSX.Element}
 * @constructor
 */
const CancelPage = () => {
    return (
        <>
            <SEOTools title='Tilauksen peruutus' />
            <section className='full-screen-demo-section'>
                <iframe
                    title='Tilauksen peruutus'
                    className='w-100 border-0 full-screen-demo'
                    src='https://framer.com/embed/DEV-MODULES--xVw9mHWk5OIXhkleG2qC/VxUXYtLKV?highlights=0'
                />
            </section>

            <DemoSelector />
        </>
    )
}

export default CancelPage
